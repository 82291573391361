@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:200,400,300,500,700);
@import "./style/common.scss";
#body {
  @include coolback;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

:root {
  font-family: Arial;
  --shadow-color: 220deg 8% 78%;
  --shadow-elevation-low: 0.3px 0px 0.3px hsl(var(--shadow-color) / 0.22),
    0.5px 0px 0.5px -1.6px hsl(var(--shadow-color) / 0.18),
    1px -0.1px 1px -3.3px hsl(var(--shadow-color) / 0.15);
  --shadow-elevation-medium: 0.3px 0px 0.3px hsl(var(--shadow-color) / 0.18),
    0.7px 0px 0.7px -0.8px hsl(var(--shadow-color) / 0.17),
    1.3px -0.1px 1.3px -1.6px hsl(var(--shadow-color) / 0.15),
    2.6px -0.2px 2.6px -2.5px hsl(var(--shadow-color) / 0.13),
    5.2px -0.4px 5.2px -3.3px hsl(var(--shadow-color) / 0.11);
  --shadow-elevation-high: 0.3px 0px 0.3px hsl(var(--shadow-color) / 0.17),
    0.8px -0.1px 0.8px -0.4px hsl(var(--shadow-color) / 0.16),
    1.3px -0.1px 1.3px -0.7px hsl(var(--shadow-color) / 0.15),
    1.9px -0.1px 1.9px -1.1px hsl(var(--shadow-color) / 0.15),
    2.9px -0.2px 2.9px -1.5px hsl(var(--shadow-color) / 0.14),
    4.3px -0.3px 4.3px -1.8px hsl(var(--shadow-color) / 0.13),
    6.3px -0.4px 6.3px -2.2px hsl(var(--shadow-color) / 0.12),
    9px -0.6px 9.1px -2.6px hsl(var(--shadow-color) / 0.11),
    12.6px -0.9px 12.7px -2.9px hsl(var(--shadow-color) / 0.11),
    17.2px -1.2px 17.3px -3.3px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-blue-medium: 0px 0.2px 0.3px hsl(var(--shadow-color) / 0),
    0px 0.6px 0.9px hsl(var(--shadow-color) / 0.13),
    0.1px 1.1px 1.7px hsl(var(--shadow-color) / 0.26),
    0.1px 1.9px 2.9px hsl(var(--shadow-color) / 0.39),
    0.2px 3.3px 5px hsl(var(--shadow-color) / 0.52);
}

$selectColor: #da5359;

.report-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.loading1 {
  height: 1px;
  background-color: $selectColor; //#e29300;
  position: absolute;
  z-index: 1504;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html {
  -webkit-locale: "ro";
}
.not-authenticated {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .not-authenticated__title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  .not-authenticated__subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: #333;
    margin-bottom: 1rem;
  }
  .not-authenticated__button {
    background-color: #da5359;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #c74a4f;
    }
  }
}
.error {
  position: absolute;
  display: block;
  width: 100%;
  height: 100px;
  bottom: 0;
  background-color: #c74a4f;
  color: #f5f5f5;
}
