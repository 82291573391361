@import "./common.scss";
/* -------------------------------------------------------------------------- */
/* ------------------------------ ALL THE FORMS ----------------------------- */
/* -------------------------------------------------------------------------- */
.versionFour {
  // need no more:
  max-width: 1415px;
  padding: 0px 0px;
  margin: 0 auto;
  &.onsmall {
    flex: 1;
  }
  &.extra-padd {
    padding-left: 27px !important;
  }
}

$yellowEditied: #fff9ed;

.commonstyle-element {
  display: flex;
  flex-wrap: wrap;
  max-height: 41 !important;
  background-color: white;

  .dx-texteditor.dx-editor-underlined {
    border: none;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .dx-texteditor.dx-editor-outlined {
    border: none;
    padding-left: 5px !important;
    padding-right: 5px !important;
    border-radius: 0px !important;
  }

  .dx-placeholder {
    @include editPlaceholder;
  }
  &.dx-invalid {
    border-bottom: 1px solid #da585944 !important;
    background-color: #fff1ed !important;
  }

  .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered)
    .dx-dropdowneditor-icon {
    background-color: transparent;
    border-color: transparent;
  }

  &.dx-radiogroup .dx-radiobutton,
  &.dx-radiogroup .dx-radiobutton-icon {
    margin: 1px 0;
    line-height: 2.5em;
  }
}

.commonstyle-input {
  border: none;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 0px !important;
}

textarea {
  &.commonstyle-input {
    height: auto !important;
  }
}

.filledunderline {
  border-radius: 0px !important;
  border-color: $yellowEditied !important;
  &.dx-state-focused,
  &.dx-state-active {
    border-bottom-color: $selectColor !important;
    background-color: $yellowEditied !important;
    color: "#333" !important;
    &.dx-field-item-label {
      color: $selectColor !important;
    }
  }
  .dx-texteditor-input {
    color: "#333";
  }
}

.filledunderline-noframe {
  @extend .filledunderline;
  border: none !important;
  .dx-texteditor-input {
    border-radius: 0px !important;
    border: none !important;
  }
}

.commonstyle-selectedLabel {
  .dx-field-item-label-text {
    color: $selectColor !important;
    transform: scale(2);
  }
  color: $selectColor !important;
  // text-decoration: underline;
}

.emptyform-message {
  // flex: 1 1;
  max-width: 1115px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  font-size: 1rem;
  padding: 40px;
  line-height: 2rem;
  background: $yellowEditied;
}
