@import "../../style/common.scss";
.dx-overlay-content:has(#drawer-main) {
  min-width: 70px;
}
#drawer-main {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: transparent;
  box-shadow: var(--shadow-elevation-blue-medium);
}

@mixin home-wh-btn {
  position: relative;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px 32px;
  pointer-events: all;
  // width: 66px;
  background-color: transparent !important;
  &.dx-state-hover,
  &.dx-state-active,
  :hover,
  :focus,
  :active {
    background-color: transparent !important;
  }
  .dx-button-text {
    position: absolute;
    bottom: 0;
    color: gray;
  }
  &.dx-item-selected {
    .dx-button-text {
      color: $selectColor;
    }
  }
}

.icoTimeSheet {
  @include home-wh-btn;

  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='64px' height='64px' viewBox='0 0 64 64' enable-background='new 0 0 64 64' xml:space='preserve'%3E%3Cg id='Layer_3'%3E%3Cpolygon fill='%23A1ADB8' points='50,25 28,25 28,29 36.7,38 28,47 28,51 50,51 50,47 33.6,47 42,38 33.6,29 50,29 '/%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cpath fill='%23A1ADB8' d='M58,5H6C4.9,5,4,5.9,4,7v50c0,1.1,0.9,2,2,2h52c1.1,0,2-0.9,2-2V7C60,5.9,59.1,5,58,5z M57,55 c0,0.5-0.5,1-1,1H8c-0.5,0-1-0.5-1-1V9c0-0.5,0.5-1,1-1h48c0.5,0,1,0.5,1,1V55z'/%3E%3Crect x='10' y='39' fill='%23A1ADB8' width='11' height='14'/%3E%3Crect x='10' y='23' fill='%23A1ADB8' width='11' height='14'/%3E%3Crect x='10' y='11' fill='%23A1ADB8' width='11' height='10'/%3E%3Crect x='23' y='11' fill='%23A1ADB8' width='14' height='10'/%3E%3Crect x='39' y='11' fill='%23A1ADB8' width='15' height='10'/%3E%3C/g%3E%3C/svg%3E%0A");
  &:hover {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23b0c6e6'%3E%3Cpolygon points='50 25 28 25 28 29 36.7 38 28 47 28 51 50 51 50 47 33.6 47 42 38 33.6 29 50 29' fill='%23b0c6e6'/%3E%3C/g%3E%3Cg fill='%23b0c6e6'%3E%3Cpath d='M58,5H6C4.9,5,4,5.9,4,7v50c0,1.1,0.9,2,2,2h52c1.1,0,2-0.9,2-2V7C60,5.9,59.1,5,58,5z M57,55 c0,0.5-0.5,1-1,1H8c-0.5,0-1-0.5-1-1V9c0-0.5,0.5-1,1-1h48c0.5,0,1,0.5,1,1V55z'/%3E%3Crect x='10' y='39' width='11' height='14'/%3E%3Crect x='10' y='23' width='11' height='14'/%3E%3Crect x='10' y='11' width='11' height='10'/%3E%3Crect x='23' y='11' width='14' height='10'/%3E%3Crect x='39' y='11' width='15' height='10'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &.dx-item-selected {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23172350'%3E%3Cpolygon points='50 25 28 25 28 29 36.7 38 28 47 28 51 50 51 50 47 33.6 47 42 38 33.6 29 50 29' fill='%23172350'/%3E%3C/g%3E%3Cg fill='%23172350'%3E%3Cpath d='M58,5H6C4.9,5,4,5.9,4,7v50c0,1.1,0.9,2,2,2h52c1.1,0,2-0.9,2-2V7C60,5.9,59.1,5,58,5z M57,55 c0,0.5-0.5,1-1,1H8c-0.5,0-1-0.5-1-1V9c0-0.5,0.5-1,1-1h48c0.5,0,1,0.5,1,1V55z'/%3E%3Crect x='10' y='39' width='11' height='14' fill-opacity='.56'/%3E%3Crect x='10' y='23' width='11' height='14' fill-opacity='.56'/%3E%3Crect x='10' y='11' width='11' height='10' fill-opacity='.56'/%3E%3Crect x='23' y='11' width='14' height='10' fill-opacity='.56'/%3E%3Crect x='39' y='11' width='15' height='10' fill-opacity='.56'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

.icoBubble {
  @include home-wh-btn;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='7.2314' cy='52.606' rx='5.2979' ry='5.3132' fill='%23a1adb8' stroke-width='2.1222'/%3E%3Cellipse cx='6.5054' cy='18.513' rx='2.5054' ry='2.5127' fill='%23a1adb8' opacity='.5' stroke-width='1.0036'/%3E%3Ccircle cx='26.348' cy='17.408' r='4.9052' fill='%23a1adb8' opacity='.5' stroke-width='1.962'/%3E%3Ccircle cx='33.243' cy='26.93' r='2.5652' fill='%23a1adb8' opacity='.5' stroke-width='1.0261'/%3E%3Ccircle cx='36.164' cy='46.468' r='5.5433' fill='%23a1adb8' opacity='.5' stroke-width='2.2173'/%3E%3Cellipse cx='50.787' cy='45.548' rx='2.5707' ry='2.5782' fill='%23a1adb8' opacity='.5' stroke-width='1.0298'/%3E%3Cellipse cx='14.094' cy='36.24' rx='3.9343' ry='3.9457' fill='%23a1adb8' stroke-width='1.576'/%3E%3Ccircle cx='24.008' cy='44.373' r='2.5652' fill='%23a1adb8' stroke-width='1.0261'/%3E%3Ccircle cx='44.642' cy='27.736' r='7.0324' fill='%23a1adb8' stroke-width='2.8129'/%3E%3Cellipse cx='53.727' cy='10.911' rx='7.8201' ry='7.8427' fill='%23a1adb8' stroke-width='3.1326'/%3E%3C/svg%3E%0A");
  &:hover {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='7.2314' cy='52.606' rx='5.2979' ry='5.3132' fill='%23b0c6e6' stroke-width='2.1222'/%3E%3Cellipse cx='6.5054' cy='18.513' rx='2.5054' ry='2.5127' fill='%23b0c6e6' opacity='.5' stroke-width='1.0036'/%3E%3Ccircle cx='26.348' cy='17.408' r='4.9052' fill='%23b0c6e6' opacity='.5' stroke-width='1.962'/%3E%3Ccircle cx='33.243' cy='26.93' r='2.5652' fill='%23b0c6e6' opacity='.5' stroke-width='1.0261'/%3E%3Ccircle cx='36.164' cy='46.468' r='5.5433' fill='%23b0c6e6' opacity='.5' stroke-width='2.2173'/%3E%3Cellipse cx='50.787' cy='45.548' rx='2.5707' ry='2.5782' fill='%23b0c6e6' opacity='.5' stroke-width='1.0298'/%3E%3Cellipse cx='14.094' cy='36.24' rx='3.9343' ry='3.9457' fill='%23b0c6e6' stroke-width='1.576'/%3E%3Ccircle cx='24.008' cy='44.373' r='2.5652' fill='%23b0c6e6' stroke-width='1.0261'/%3E%3Ccircle cx='44.642' cy='27.736' r='7.0324' fill='%23b0c6e6' stroke-width='2.8129'/%3E%3Cellipse cx='53.727' cy='10.911' rx='7.8201' ry='7.8427' fill='%23b0c6e6' stroke-width='3.1326'/%3E%3C/svg%3E%0A");
  }
  &.dx-item-selected {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='7.2314' cy='52.606' rx='5.2979' ry='5.3132' fill='%23172350' stroke-width='2.1222'/%3E%3Cellipse cx='6.5054' cy='18.513' rx='2.5054' ry='2.5127' fill='%23172350' opacity='.5' stroke-width='1.0036'/%3E%3Ccircle cx='26.348' cy='17.408' r='4.9052' fill='%23172350' opacity='.5' stroke-width='1.962'/%3E%3Ccircle cx='33.243' cy='26.93' r='2.5652' fill='%23172350' opacity='.5' stroke-width='1.0261'/%3E%3Ccircle cx='36.164' cy='46.468' r='5.5433' fill='%23172350' opacity='.5' stroke-width='2.2173'/%3E%3Cellipse cx='50.787' cy='45.548' rx='2.5707' ry='2.5782' fill='%23172350' opacity='.5' stroke-width='1.0298'/%3E%3Cellipse cx='14.094' cy='36.24' rx='3.9343' ry='3.9457' fill='%23172350' stroke-width='1.576'/%3E%3Ccircle cx='24.008' cy='44.373' r='2.5652' fill='%23172350' stroke-width='1.0261'/%3E%3Ccircle cx='44.642' cy='27.736' r='7.0324' fill='%23172350' stroke-width='2.8129'/%3E%3Cellipse cx='53.727' cy='10.911' rx='7.8201' ry='7.8427' fill='%23172350' stroke-width='3.1326'/%3E%3C/svg%3E%0A");
  }
}

.icoSankeyUser {
  @include home-wh-btn;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='57' y='6' width='3' height='34' fill='%23a1adb8'/%3E%3Crect x='57' y='44' width='3' height='14' fill='%23a1adb8'/%3E%3Cpath d='m55 58c-13 0-20.7-11.3-27.5-21.3-5.9-8.6-11.4-16.7-18.5-16.7v-14c14.5 0 22.7 12.1 30 22.8 5.4 7.8 10.4 15.2 16 15.2z' fill='%23a1adb8' opacity='.5'/%3E%3Cpath d='m9 58v-16c6.8 0 11.4-3.2 17.2-7.2 6.9-4.8 15.5-12.8 28.8-12.8v18c-8.3 0-13.6 3.7-19.7 7.9-6.8 4.7-14.5 10.1-26.3 10.1z' fill='%23a1adb8' opacity='.5'/%3E%3Cpath d='m9 38v-14c7.1 0 11.8-3.3 17.8-7.4 7.1-5 15.2-10.6 28.2-10.6v16c-8.6 0-14 1.7-20.3 6.1-6.7 4.6-14.2 9.9-25.7 9.9z' fill='%23a1adb8' opacity='.5'/%3E%3Cpath d='m8.7235 12.293a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23a1adb8' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.6165 31.029a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23a1adb8' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.5305 50.885a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23a1adb8' style='paint-order:markers stroke fill'/%3E%3C/svg%3E%0A");
  &:hover {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='57' y='6' width='3' height='34' fill='%23638fcd'/%3E%3Crect x='57' y='44' width='3' height='14' fill='%23638fcd'/%3E%3Cpath d='m55 58c-13 0-20.7-11.3-27.5-21.3-5.9-8.6-11.4-16.7-18.5-16.7v-14c14.5 0 22.7 12.1 30 22.8 5.4 7.8 10.4 15.2 16 15.2z' fill='%23638fcd' opacity='.5'/%3E%3Cpath d='m9 58v-16c6.8 0 11.4-3.2 17.2-7.2 6.9-4.8 15.5-12.8 28.8-12.8v18c-8.3 0-13.6 3.7-19.7 7.9-6.8 4.7-14.5 10.1-26.3 10.1z' fill='%23638fcd' opacity='.5'/%3E%3Cpath d='m9 38v-14c7.1 0 11.8-3.3 17.8-7.4 7.1-5 15.2-10.6 28.2-10.6v16c-8.6 0-14 1.7-20.3 6.1-6.7 4.6-14.2 9.9-25.7 9.9z' fill='%23638fcd' opacity='.5'/%3E%3Cpath d='m8.7235 12.293a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23638fcd' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.6165 31.029a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23638fcd' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.5305 50.885a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23638fcd' style='paint-order:markers stroke fill'/%3E%3C/svg%3E%0A");
  }
  &.dx-item-selected {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 64 64' version='1.1' viewBox='0 0 64 64' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='57' y='6' width='3' height='34' fill='%23172350'/%3E%3Crect x='57' y='44' width='3' height='14' fill='%23172350'/%3E%3Cpath d='m55 58c-13 0-20.7-11.3-27.5-21.3-5.9-8.6-11.4-16.7-18.5-16.7v-14c14.5 0 22.7 12.1 30 22.8 5.4 7.8 10.4 15.2 16 15.2z' fill='%23172350' opacity='.5'/%3E%3Cpath d='m9 58v-16c6.8 0 11.4-3.2 17.2-7.2 6.9-4.8 15.5-12.8 28.8-12.8v18c-8.3 0-13.6 3.7-19.7 7.9-6.8 4.7-14.5 10.1-26.3 10.1z' fill='%23172350' opacity='.5'/%3E%3Cpath d='m9 38v-14c7.1 0 11.8-3.3 17.8-7.4 7.1-5 15.2-10.6 28.2-10.6v16c-8.6 0-14 1.7-20.3 6.1-6.7 4.6-14.2 9.9-25.7 9.9z' fill='%23172350' opacity='.5'/%3E%3Cpath d='m8.7235 12.293a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23172350' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.6165 31.029a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23172350' style='paint-order:markers stroke fill'/%3E%3Cpath d='m8.5305 50.885a4.1469 4.1274 0 0 1-4.1372 4.1274 4.1469 4.1274 0 0 1-4.1565-4.1082 4.1469 4.1274 0 0 1 4.1178-4.1466 4.1469 4.1274 0 0 1 4.1757 4.0888' fill='%23172350' style='paint-order:markers stroke fill'/%3E%3C/svg%3E%0A");
  }
}

.icoGroup {
  @include home-wh-btn;
  background-size: 24px 24px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' xmlns:cc='http://creativecommons.org/ns%23' xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23'%3E%3Ctitle%3Egroup%3C/title%3E%3Cpath d='m36.247 14.843c0 7.0933-5.7492 12.843-12.843 12.843-7.0933 0-12.843-5.7492-12.843-12.843 0-7.0933 5.7492-12.843 12.843-12.843 7.0933 0 12.843 5.7492 12.843 12.843z' fill='%23a1adb8' stroke-width='4.2808'/%3E%3Cpath d='m44.808 61.932h-42.808v-9.9315c0.047089-1.3656 0.18408-2.667 0.41096-3.9341l-0.025685 0.16695c2.2004-6.2072 4.9615-11.584 8.3348-16.511l-0.15839 0.24401c3.0137 2.6969 7.012 4.3408 11.396 4.3408 0.50942 0 1.0146-0.0214 1.5111-0.06421l-0.06421 0.0043c0.43236 0.03853 0.9375 0.05993 1.4469 0.05993 4.3836 0 8.3819-1.6481 11.413-4.3579l-0.01712 0.01284c3.1978 4.6832 5.9461 10.06 7.9795 15.775l0.15411 0.4923c0.21832 1.2457 0.34247 2.6841 0.34247 4.1524v0.13699-0.0086z' fill='%23a1adb8' stroke-width='4.2808'/%3E%3Cpath d='m61.589 48.233c-2.2132-6.2072-4.9915-11.584-8.3733-16.507l0.15411 0.23973c-1.5411 1.2628-3.3048 2.3373-5.2098 3.155l-0.14127 0.05565c2.089 3.3476 3.7415 7.2346 4.7389 11.374l0.05565 0.26969c0.32106 1.6695 0.52226 3.6045 0.55651 5.5779v9.5334h8.5617v-9.5035-0.04709c0-1.464-0.12414-2.9024-0.36387-4.298z' fill='%23a1adb8' opacity='.63519' stroke-width='4.2808'/%3E%3Cpath d='m44.808 14.843c-0.02997 3.7115-1.0103 7.1918-2.7098 10.21l0.05565-0.10702 1.3699 2.3545c5.6293-1.4426 9.7218-6.4726 9.7218-12.457 0-7.0505-5.6807-12.774-12.714-12.843h-0.0086c2.6712 3.5317 4.2808 8.0009 4.2808 12.843z' fill='%23a1adb8' opacity='.63519' stroke-width='4.2808'/%3E%3Cmetadata%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:title%3Egroup%3C/dc:title%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3C/svg%3E");
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' xmlns:cc='http://creativecommons.org/ns%23' xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23'%3E%3Ctitle%3Egroup%3C/title%3E%3Cpath d='m36.247 14.843c0 7.0933-5.7492 12.843-12.843 12.843-7.0933 0-12.843-5.7492-12.843-12.843 0-7.0933 5.7492-12.843 12.843-12.843 7.0933 0 12.843 5.7492 12.843 12.843z' fill='%23b0c6e6' stroke-width='4.2808'/%3E%3Cpath d='m44.808 61.932h-42.808v-9.9315c0.047089-1.3656 0.18408-2.667 0.41096-3.9341l-0.025685 0.16695c2.2004-6.2072 4.9615-11.584 8.3348-16.511l-0.15839 0.24401c3.0137 2.6969 7.012 4.3408 11.396 4.3408 0.50942 0 1.0146-0.0214 1.5111-0.06421l-0.06421 0.0043c0.43236 0.03853 0.9375 0.05993 1.4469 0.05993 4.3836 0 8.3819-1.6481 11.413-4.3579l-0.01712 0.01284c3.1978 4.6832 5.9461 10.06 7.9795 15.775l0.15411 0.4923c0.21832 1.2457 0.34247 2.6841 0.34247 4.1524v0.13699-0.0086z' fill='%23b0c6e6' stroke-width='4.2808'/%3E%3Cpath d='m61.589 48.233c-2.2132-6.2072-4.9915-11.584-8.3733-16.507l0.15411 0.23973c-1.5411 1.2628-3.3048 2.3373-5.2098 3.155l-0.14127 0.05565c2.089 3.3476 3.7415 7.2346 4.7389 11.374l0.05565 0.26969c0.32106 1.6695 0.52226 3.6045 0.55651 5.5779v9.5334h8.5617v-9.5035-0.04709c0-1.464-0.12414-2.9024-0.36387-4.298z' fill='%23b0c6e6' opacity='.63519' stroke-width='4.2808'/%3E%3Cpath d='m44.808 14.843c-0.02997 3.7115-1.0103 7.1918-2.7098 10.21l0.05565-0.10702 1.3699 2.3545c5.6293-1.4426 9.7218-6.4726 9.7218-12.457 0-7.0505-5.6807-12.774-12.714-12.843h-0.0086c2.6712 3.5317 4.2808 8.0009 4.2808 12.843z' fill='%23b0c6e6' opacity='.63519' stroke-width='4.2808'/%3E%3Cmetadata%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:title%3Egroup%3C/dc:title%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3C/svg%3E");
  }
  &.dx-item-selected {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' xmlns:cc='http://creativecommons.org/ns%23' xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23'%3E%3Ctitle%3Egroup%3C/title%3E%3Cpath d='m36.247 14.843c0 7.0933-5.7492 12.843-12.843 12.843-7.0933 0-12.843-5.7492-12.843-12.843 0-7.0933 5.7492-12.843 12.843-12.843 7.0933 0 12.843 5.7492 12.843 12.843z' fill='%23172350' stroke-width='4.2808'/%3E%3Cpath d='m44.808 61.932h-42.808v-9.9315c0.047089-1.3656 0.18408-2.667 0.41096-3.9341l-0.025685 0.16695c2.2004-6.2072 4.9615-11.584 8.3348-16.511l-0.15839 0.24401c3.0137 2.6969 7.012 4.3408 11.396 4.3408 0.50942 0 1.0146-0.0214 1.5111-0.06421l-0.06421 0.0043c0.43236 0.03853 0.9375 0.05993 1.4469 0.05993 4.3836 0 8.3819-1.6481 11.413-4.3579l-0.01712 0.01284c3.1978 4.6832 5.9461 10.06 7.9795 15.775l0.15411 0.4923c0.21832 1.2457 0.34247 2.6841 0.34247 4.1524v0.13699-0.0086z' fill='%23172350' stroke-width='4.2808'/%3E%3Cpath d='m61.589 48.233c-2.2132-6.2072-4.9915-11.584-8.3733-16.507l0.15411 0.23973c-1.5411 1.2628-3.3048 2.3373-5.2098 3.155l-0.14127 0.05565c2.089 3.3476 3.7415 7.2346 4.7389 11.374l0.05565 0.26969c0.32106 1.6695 0.52226 3.6045 0.55651 5.5779v9.5334h8.5617v-9.5035-0.04709c0-1.464-0.12414-2.9024-0.36387-4.298z' fill='%23172350' opacity='.63519' stroke-width='4.2808'/%3E%3Cpath d='m44.808 14.843c-0.02997 3.7115-1.0103 7.1918-2.7098 10.21l0.05565-0.10702 1.3699 2.3545c5.6293-1.4426 9.7218-6.4726 9.7218-12.457 0-7.0505-5.6807-12.774-12.714-12.843h-0.0086c2.6712 3.5317 4.2808 8.0009 4.2808 12.843z' fill='%23172350' opacity='.63519' stroke-width='4.2808'/%3E%3Cmetadata%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:title%3Egroup%3C/dc:title%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3C/svg%3E");
  }
}

.noIco {
  @include home-wh-btn;
}

.drawer-item {
  padding: 0px 0px 2px 0px;
}
