$selectColor: #da5359; // Indian Red //
// $selectColor: #D57C9F; // China Pink //
$selectColorWithOpacity: #da58596b;
$selectColorGreyOpacity: #cccccc;
$delphiblue: #9ab7de;
$delphititleBlue: #7c9fd5; //$00D59F7C
$whGlyphBlueColor: #d7dff0ff;
$icontitleblueColorWithOpacity: #337ab766;
$colory-tm-grey: #a7a8a8;
$colory-tm-grey-blue: #84bcd9; // or lighten grey-ish monocromish ($colory-tm-blue, 10%)
$colory-tm-grey-red: #c28285;
$colory-tm-red: #c7383f;
$colory-tm-blue: #0578b5;
$colorIconNormal: #a1adb8ff;
$colorIconHovered: #638fcdff;
$colorIconSelected: #172350ff;
$transparentVioletish: #9799af40;
$colory-heatmap-background: #f2f6fa;
$backSaveValid: #95b195;
$backSaveValidHover: #2ea043;
//Lavender Gray :  very interesting color. near blue seems pink near pink seems blue. nice look on hover in delphi.
$lavenderGray: #b6b6cb;
.k-form {
  width: 100vw;
  background-size: cover;
  background-color: white;
}

@mixin titleButton {
  flex: 1;
  margin: auto;
  padding-right: -3px;
  right: 25px;
  top: 0px;

  .dx-icon {
    font-size: 29px !important;
    color: darken($whGlyphBlueColor, 10%) !important;
    display: flex;
    justify-content: center;
    margin: auto;
    // padding-top: 4px; //
    transform: translateX(-5px);
  }
  &.tv-with-maximized-item {
    box-shadow: 3px -4px 20px 0px rgb(10 47 78 / 10%), 60px 0px 100px -90px #ddd !important;
    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #d3d3d3;
    }
    .dx-icon {
      color: $selectColor !important;
    }
  }
  &.dx-state-disabled {
    .dx-icon {
      opacity: 1 !important;
      color: darken($whGlyphBlueColor, 40%);
    }
  }
}
@mixin editPlaceholder {
  padding-left: 5px !important;
  padding-right: 5px !important ;
  font-style: italic;
  color: #99999975;
  font-size: 0.85rem !important;
}

@mixin titlelabel {
  color: #294e81;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

@mixin backdivider {
  background: linear-gradient(
    90deg,
    #acc7df 0%,
    rgb(255, 255, 255) 3px,
    rgba(255, 255, 255, 1) 10px
  );
}
@mixin home-grid-btn {
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 28px;
  pointer-events: all;
  width: 66px;
  background-color: transparent !important;
  &.dx-state-hover,
  &.dx-state-active,
  :hover,
  :focus,
  :active {
    background-color: transparent !important;
  }
}

@mixin coolback {
  background: linear-gradient(
    -45deg,
    #dd98831f,
    #bd718e1f,
    #6992a11f,
    #2533301f
  );
  // background: linear-gradient(-45deg, #ac824c37, #ad4a7036, #4d8ca337, #393d7037);
  background-size: 400% 400%;
  animation: gradient 75s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.icoFullReset {
  @include home-grid-btn;
  .dx-icon-home {
    padding: 2px !important;
    &::before {
      color: #a1adb8ff;
      font-size: 28px;
    }
  }
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
    .dx-icon-home {
      &::before {
        color: #638fcdff;
      }
    }
  }
  &.dx-item-selected {
    background-color: transparent !important;
    .dx-icon-home {
      &::before {
        color: #172350ff;
      }
    }
  }
}
.pinned {
  .dx-icon-pin {
    &::before {
      color: $selectColor !important;
    }
  }
}
.userpick {
  .dx-tag {
    align-self: center;
  }
  &.dx-swatch-teamZebra .dx-tag-content {
    background-color: transparent !important;
    border-radius: 5px;
    // height: 28px;
    &:hover {
      background-color: transparent !important;
      color: $selectColor;
    }
  }
  .dx-icon-pin,
  .dx-icon-unpin {
    &::before {
      font-size: 1.3em;
    }
  }
}
.searchable {
  &.dx-swatch-teamZebra
    .dx-tag-container.dx-texteditor-input-container
    .dx-texteditor-input {
    background: $transparentVioletish;
    font-style: italic;
    padding: 5px;
    width: stretch;
    width: -webkit-fill-available;
  }
}
.hedpinItem {
  padding: 5px;
  transform: rotate(135deg);
  height: 30px;
  width: 30px;
  cursor: pointer;
  &:hover {
    background-color: transparent !important;
  }
}
.break {
  flex-basis: 100%;
  height: 0;
}
/* -------------------------------------------------------------------------- */
// - culoare icon wh - window header blue : d7dff0ff
// - culoare verde: 7dd77b
// - culoare rosu: da5359
// - culoare albastru: 7c9fd5
// - culoare oranj: E2A670 ( $0070A6E2 )
// - culoare galben: D9D579 ( $0079D5D9 )
