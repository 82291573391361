.dx-swatch-teamZebraGridTimeSheet
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td,
.dx-swatch-teamZebraGridTimeSheet
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td,
.dx-swatch-teamZebraGridTimeSheet
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td,
.dx-swatch-teamZebraGridTimeSheet
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: #a6bfe012;
  color: #211d1d;
}

.dx-swatch-teamZebraGridTimeSheet .dx-toolbar .dx-toolbar-items-container {
  height: 43px !important;
  overflow: visible;
}

.dx-swatch-teamZebraGridTimeSheet .dx-show-clear-button .dx-icon-clear {
  background-size: 12px 12px;
  font-size: 12px;
  line-height: 12px;
}

.dx-swatch-teamZebraGridTimeSheet.dx-texteditor.dx-editor-underlined {
  background: 0 0;
  border-bottom: none;
  border-radius: 0;
  &:hover,
  &:focus {
    border-bottom: none !important;
  }
}
.dx-swatch-teamZebraGridTimeSheet
  .dx-toolbar-text-auto-hide
  .dx-button
  .dx-icon,
.dx-swatch-teamZebraGridTimeSheet .dx-button-has-icon .dx-icon {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin-right: 0;
  margin-left: 0;
}
.dx-freespace-row {
  visibility: hidden !important;
}
//anygrid//
@mixin karmagrid {
  background: white;
  display: block;
  margin: 0 auto;
  margin-top: 1px;
  box-shadow: -60px 0px 100px -90px #dee1e3, 60px 0px 100px -90px #dee1e3;
  @media screen and (max-width: 1740px) {
    box-shadow: none;
  }
  padding: 5px !important;
  padding-left: 20px !important; // to make date visible on sidebar sliding
  padding-right: 20px !important;
  .dx-swatch-teamZTimeSheet .dx-datagrid-header-panel .dx-toolbar {
    margin-top: 15px;
    margin-bottom: 5px;
    /* height: 40px; */
  }
  .dx-swatch-teamZTimeSheet .dx-datagrid-summary-item {
    color: #130b22;
    text-align: right !important;
  }
  .dx-texteditor {
    &.dx-state-hover,
    &.dx-state-focused {
      &:before {
        border-bottom: 1px solid $selectColorWithOpacity !important;
      }
    }
  }
  &.dx-swatch-teamZebraGridTimeSheet .dx-button-mode-contained {
    background-color: white;
    border: 1px solid #f1d1d2;
    width: 26px;
    height: 26px;
    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #d3d3d3;
    }
  }
  ///pe ecran mic, sa fac loc la butoane
  &.onSmall {
    .dx-datagrid-header-panel {
      height: 80px !important;
    }
  }
  // nu intreba//
  // .dx-texteditor.dx-state-focused::before{
  //   border-bottom: 1px solid #da585879 !important;
  // }
}
