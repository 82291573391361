@import "@app/style/common.scss";
@import "@app/style/common-grids.scss";
@import "@app/style/commonForms.scss";
.anyGrid,
.contactsgrid {
  @include karmagrid;
}
$bluePaleGray: #97a5c0;
#grdLayoutButton {
  .dx-icon-doc {
    transform: rotate(270deg);
  }
}
#searchbox-random-id-971 {
  .dx-icon-search {
    transform: rotate(270deg);
  }
}
#anyGrid-LIST_CONTACTE_ORIGIN,
#anyGrid-LIST_PERSOANE_ORIGIN,
#anyGrid-LIST_CONTACTE_PERSOANE_ORIGIN,
#anyGrid-LIST_WORK_ORIGIN,
#anyGrid-LIST_AVOCATI_ORIGIN,
#anyGrid-LIST_CTRDOSARE_ORIGIN,
#anyGrid-LIST_WORKDATA_ORIGIN,
#anyGrid,
#personsGrid,
#contactsGrid {
  .dx-link {
    color: $colorIconNormal;
    &:hover {
      color: $colorIconHovered;
    }
  }
  &.spdanger {
    .dx-texteditor-container {
      background-color: $selectColor;
    }
    .dx-texteditor-input {
      color: white;
    }
    .dx-texteditor-buttons-container {
      .dx-icon-clear {
        color: white;
      }
    }
  }
  .dx-header-filter:not(.dx-header-filter-empty) {
    color: $selectColor;
  }
}

.dx-truncated-text-header {
  height: 100%;
  width: 100%;
  padding: 5px;
}
.dx-header-button {
  width: 100%;
  margin: auto;
  border: 1px solid silver;
  border-radius: 3px;
  color: gray;
  font-size: 10px;
}
@mixin with-text-clamped {
  //[##findme012323##]
  overflow: hidden;
  line-height: 1.2rem;
  max-height: 3.8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.anyGrid.dx-truncated-text {
  //[##findme012323##]
  .dx-field-long-text > p {
    @include with-text-clamped;
  }
}
#helpedInfo2,
#helpedInfo {
  color: $colorIconNormal;
}

#tooltip-content {
  font-size: 14px;
  font-weight: 200;
}

#template-content {
  display: inline-flex;
}

.dx-field-item-content-score {
  background-color: #f5f5f5;
  .nomatch {
    background-color: #ffe2e2;
  }
  .match {
    background-color: #e2ffe2;
  }
  font-weight: bold;
  font-size: 1.7em;
  width: 120px;
  margin: auto;
  border-radius: 8px;
  padding: 5px;
}
